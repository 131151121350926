import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import InputRange from "../../../common/InputRange";
import LabelText from "../../../common/LabelText";
import ProductBoxBasic from "../../../common/ProductBoxBasic";
import { getHrefs } from "../../../../utils/langService";
import OrderService from "../../../../services/OrderService";

const servicesValueMax = 21;

const SupportOffer = ({ t, lang, monitoring, getMonitoring }) => {
  const { price, configs } = monitoring.data;

  const [servicesValue, setServicesValue] = useState(configs.minQuantity);

  const updateServicesValue = e => {
    setServicesValue(Number(e.target.value));
  };

  const { pages } = getHrefs(lang);

  const addToShoppingCart = () => {
    OrderService.createOrder({ productId: monitoring.id, quantity: servicesValue, modifier: [], divide: [] });
    navigate(`/${lang}/${pages[7]}/`);
  };

  return (
    <section className="section section--gray Support__offer">
      <div className="container Support__offer__container">
        <div className="Support__offer__content">
          <h2 className="title">{t.title}</h2>
          <p>{t.description}</p>

          <div className="Support__offer__calculator">
            <p className="small-title small-title--withMargin">{t.calculator.title}:</p>
            <LabelText label={t.calculator.services} name={`${servicesValue}${servicesValue === servicesValueMax ? "+" : ""}`} />
            <LabelText
              label={t.calculator.price}
              name={`${servicesValue * (price.year / 12) + price.addedValue / 12}${servicesValue === servicesValueMax ? "+" : ""} ${price.currency}`}
            />
            <InputRange name="servicesValue" value={servicesValue} onChange={updateServicesValue} min={configs.minQuantity} max={servicesValueMax} />
          </div>
        </div>

        <div className="Support__offer__right">
          <ProductBoxBasic t={t.monitoring} click={addToShoppingCart}>
            <div>
              <ul className="list-check">
                {t.monitoring.list.map((elem, i) => (
                  <li key={i} className={`${!elem.active ? "transparent" : ""}`}>
                    {elem.name}
                  </li>
                ))}
              </ul>
              <div className="Support__offer__stars">
                {t.monitoring.stars.map((elem, i) => (
                  <p key={i}>{elem}</p>
                ))}
              </div>
            </div>
          </ProductBoxBasic>

          <ProductBoxBasic t={t.maintenance} link={`/${lang}/${pages[3]}/`}>
            <ul className="list-check">
              {t.maintenance.list.map((elem, i) => (
                <li key={i} className={`${!elem.active ? "transparent" : ""}`}>
                  {elem.name}
                </li>
              ))}
            </ul>
          </ProductBoxBasic>
        </div>
      </div>
    </section>
  );
};

SupportOffer.propTypes = {
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  monitoring: PropTypes.object.isRequired,
  getMonitoring: PropTypes.func.isRequired
};

export default SupportOffer;

import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import Advantages from "../../../common/Advantages";
import SupportOfferContainer from "./SupportOfferContainer";
import { getHrefs } from "../../../../utils/langService";
import { getShareImage } from "../../../../utils/getShareImage";

import imgMaintenance from "../../../../images/maintenance.jpg";
import imgMonitoring from "../../../../images/monitoring.jpg";
import imgSupport from "../../../../images/support.jpg";
import imgEnvelope from "../../../../images/common/envelope.svg";
import imgReport from "../../../../images/common/report.svg";
import imgControl from "../../../../images/common/control.svg";
import imgExperience from "../../../../images/common/experience.svg";
import imgInnovation from "../../../../images/common/innovation.svg";
import imgLightning from "../../../../images/common/lightning.svg";
import imgHeart from "../../../../images/common/heart.svg";

const Support = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const { pages } = getHrefs(lang);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.software.title }]} />

      <div className="Software">
        <BasicSection t={t.maintenance} image={imgMaintenance} />

        <BasicSection t={t.monitoring} image={imgMonitoring} reverse />

        <Advantages t={t.monitoringAdvantages} images={[imgEnvelope, imgReport, imgControl]} columns3 nopadding />

        <SupportOfferContainer t={t.offer} lang={lang} />

        <BasicSection t={t.support} image={imgSupport} link={`/${lang}/${pages[3]}/`} />

        <Advantages t={t.advantages} images={[imgExperience, imgInnovation, imgLightning, imgHeart]} nopadding />
      </div>
    </Layout>
  );
};

Support.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Support;

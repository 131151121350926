import SupportOffer from "./SupportOffer";
import { connect } from "react-redux";
import { getMonitoring } from "../../../../store/packages/monitoring/actions";

const mapStateToProps = state => ({
  monitoring: state.monitoring
});

const mapDispatchToProps = dispatch => ({
  getMonitoring: async () => dispatch(await getMonitoring())
});

const SupportOfferContainer = connect(mapStateToProps, mapDispatchToProps)(SupportOffer);
export default SupportOfferContainer;

import React from "react";
import PropTypes from "prop-types";
import LinkButton from "./LinkButton";
import Button from "./Button";

const ProductBoxBasic = ({ children, t, link, click }) => (
  <div className="ProductBox">
    <div>
      <div className={`ProductBox__header ${!children ? "ProductBox__header--withoutBorder" : ""}`}>
        <h4 className="ProductBox__title">{t.title}</h4>
        {!!t.description && <p className="ProductBox__description">{t.description}</p>}
      </div>

      {!!children && <div className="ProductBox__children">{children}</div>}
    </div>

    <div className={`ProductBox__footer ${!children ? "ProductBox__footer--withoutBorder" : ""}`}>
      {!!t.price && (
        <div className="ProductBox__priceContainer">
          <p>{t.price.info}</p>
          <p className="ProductBox__price">
            <span>{t.price.value}</span> PLN
          </p>
          <p>{t.price.period}</p>
        </div>
      )}

      {!!click ? <Button onClick={click}>{t.btn}</Button> : <LinkButton to={link}>{t.btn}</LinkButton>}
    </div>
  </div>
);

ProductBoxBasic.propTypes = {
  children: PropTypes.node,
  t: PropTypes.object.isRequired,
  link: PropTypes.string,
  click: PropTypes.func
};

export default ProductBoxBasic;

import React from "react";
import PropTypes from "prop-types";

const LabelText = ({ label, name }) => (
  <div className="LabelText">
    <p className="LabelText__label">{label}:</p>
    <p>{name}</p>
  </div>
);

LabelText.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default LabelText;

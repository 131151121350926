import React from "react";
import PropTypes from "prop-types";

const InputRange = ({ ...attributes }) => (
  <label className="InputRange">
    <input type="range" {...attributes} />
  </label>
);

InputRange.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};

export default InputRange;

import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, white, ...attributes }) => (
  <button className={`LinkButton Button ${white ? "LinkButton--white" : ""}`} {...attributes}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  white: PropTypes.bool
};

export default Button;
